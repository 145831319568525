import React from 'react'

const Footer = () => {
    return (
        <footer>
            <div>
                <p className='centre-text'>(c) Next Concepts 2023</p>
            </div>
        </footer>
    )
}

export default Footer