import { createContext, useState, useContext } from "react"
import SupabaseContext from './SupabaseContext';

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const [isAuth, setIsAuth] = useState(false)
    const [user, setUser] = useState('')

    const { supabase } = useContext(SupabaseContext)

    function SignOut() {
        setUser('')
        setIsAuth(false)
    }

    async function SignIn() {

        const { data, error } = await supabase.auth.signInWithPassword({
            email: 'dan@nextconcepts.com.au',
            password: 'ShiraZelda23#@!',
        })

        if (data) {
            setUser(data.user.id)
            setIsAuth(true)
        } else {
            setUser('')
            setIsAuth(false)
        }
    }

    return <AuthContext.Provider value={{
        isAuth,
        setIsAuth,
        user,
        setUser,
        SignIn,
        SignOut
    }}>
        {children}
    </AuthContext.Provider>
}

export default AuthContext;