import { createContext } from "react"
import { createClient } from '@supabase/supabase-js'

const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY
const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL

const SupabaseContext = createContext()
const supabase = createClient(SUPABASE_URL, SUPABASE_KEY, { multiTab: false })

export const SupabaseProvider = ({ children }) => {

    return <SupabaseContext.Provider value={{
        supabase,
    }}>
        {children}
    </SupabaseContext.Provider>
}

export default SupabaseContext