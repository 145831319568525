import React, { useEffect, useState } from 'react'

const Hero = () => {

  const sentences = ['Digital Solutions', 'Ideas to life', 'Consulting', 'Websites', 'AI', 'Web Apps', 'Enterprise Solutions', 'Data Processing', 'Marketing']

  const [index, setIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(index + 1)
    }, 1500)

    if (index === 9) {
      setIndex(0);
    }

    return (() => clearInterval(interval)) //This is a cleanup function
  })

  return (
    <div className='App-header'>
      <div className='title'>
        <div className='welcome'>
          <p>WELCOME TO THE FUTURE</p>
        </div>
        <h2>Hi, we're Next Concepts</h2>
        <h2 className='purple'>
          {sentences[index]}
        </h2>
        <h2>Based in Australia.</h2>
        <div className='welcome-grey'>
          <p>Clever modern solutions without the agency price tag.</p>
        </div>
      </div>
    </div>
  )
}

export default Hero