import React from 'react'
import profile_image from '../assests/background/laptop.png'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const About = () => {

    return (
        <div className='container-grey'>
            <div className='container-eighty'>
                <div className="grid-container-about">
                    <div className="item1-about">
                        <img className='logo' src={profile_image} width='350' />
                    </div>
                    <div className="item2-about">
                        <h1 className='about-us-title'>About Us</h1>
                        <p className='bio'>
                            The start-up tech company based in Melbourne, Australia is committed to offering cost-effective yet high-quality solutions to its clients. By leveraging the latest technologies and adopting a customer-centric approach, the company aims to revolutionize the online industry with its fresh and innovative solutions.
                        </p>
                        <p className='bio'>
                            With a focus on affordability and quality, the company aims to empower small businesses and individuals to achieve their digital goals with ease and efficiency. Through its commitment to excellence and customer satisfaction, the start-up tech company is poised to become a leading player in the industry.
                        </p>
                        <h3 className='go-left header-purple'>
                            CONCEPTS
                        </h3>
                        <p className='bio'>
                            Next Concepts also specializes in creating online products that not only generate revenue for itself but also provide a source of income for users/content creators.
                        </p>
                        <p className='bio'>
                            By offering a range of innovative solutions such as online stores, service industry lead generation, membership solutions, organisational applicaitons, the company empowers creators to monetize their expertise and passions while building their online presence.
                        </p>
                        <p className='bio'>
                            With a focus on win-win solutions, the company is committed to creating value for both its customers and itself, while promoting entrepreneurship and creativity in the digital space.
                        </p>
                        <h3 className='go-left header-purple'>
                            FOUNDERS
                        </h3>
                        <p className='bio'>
                            Next Concepts was formed in 2023 by Matt and Dan to leverage digital technologies in a manner that creates solutions for people while supporting its creators. The company only consists of two people, one digital marketing lead, and one full stack programming lead.
                        </p>
                        <p className='bio'>
                            Working with us means you will only hear from two people, not get lost in the abyss of corporate strucutre & forwarded emails.
                        </p>

                        {/* <Tabs selectedTabClassName="select">
                            <TabList>
                                <Tab>Skills</Tab>
                                <Tab>Awards</Tab>
                                <Tab>Experience</Tab>
                                <Tab>Education & Certification</Tab>
                            </TabList>
                            <TabPanel>
                                <div className='tab-height'>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas sapiente consequatur enim aliquam mollitia harum. Eveniet cumque natus ex tempore repellat consequuntur dignissimos magni facilis. Eos maiores ipsa quaerat molestias.</p>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='tab-height'>
                                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae eaque exercitationem modi sint natus laborum laboriosam eos, adipisci beatae, aliquid hic odit, perferendis illum itaque incidunt necessitatibus. Ratione, id consequuntur!</p>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='tab-height'>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti alias repudiandae laudantium quidem impedit repellendus assumenda facilis omnis? Numquam esse voluptate nulla obcaecati nesciunt expedita fugiat cupiditate deserunt aspernatur nemo.</p>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='tab-height'>
                                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illo ea excepturi praesentium fuga, perspiciatis tempora nobis ullam. Quia recusandae ducimus officiis ea quisquam suscipit aut veniam facilis facere a? Omnis.</p>
                                </div>
                            </TabPanel>
                        </Tabs> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About