import React from 'react'

const ContactUs = () => {
    return (
        <div className='container-black'>
            <div className='container-eighty'>
                <div className='centre-title'>
                    <h1 className='heading'>Contact Us</h1>
                    <div className='grid-container-contact-us'>
                        <div className='item1-contact-us'>
                            <p>dan@nextconcepts.com.au</p>
                        </div>
                        <div className='item2-contact-us'>
                            <p>matt@nextconcepts.com.au</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs