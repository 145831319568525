import React from 'react'
import ServiceCard from './common/ServiceCard'

const Services = () => {
    return (
        <div className='container-black'>
            <div className='container-eighty'>
                <div className='centre-title'>
                    <h1 className='heading'>Services</h1>
                </div>
                <div className='grid-container-services'>
                    <div className='item1-services'>
                        <ServiceCard service='Websites' />
                    </div>
                    <div className='item2-services'>
                        <ServiceCard service='Web Apps' />
                    </div>
                    <div className='item3-services'>
                        <ServiceCard service='Enterprise Solutions' />
                    </div>
                    <div className='item4-services'>
                        <ServiceCard service='Data Processing' />
                    </div>
                    <div className='item5-services'>
                        <ServiceCard service='Digital Marketing' />
                    </div>
                    <div className='item6-services'>
                        <ServiceCard service='Ecommerce' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services