import React from 'react'
import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn, FaAngleDown } from "react-icons/fa";
import nextconceptsLogo from '../assests/logo/next_concepts_transparent.png'

const TopBar = () => {

    const SocialShare = [
        { Social: <FaFacebookF color='white' />, link: 'https://www.facebook.com/profile.php?id=100090220869003' },
        { Social: <FaLinkedinIn color='white' />, link: 'https://www.linkedin.com/' },
        { Social: <FaInstagram color='white' />, link: 'https://www.instagram.com/' },
        { Social: <FaTwitter color='white' />, link: 'https://twitter.com/' },
    ]

    const MenuItems = [
        { Social: <FaAngleDown color='white' />, Text: 'Products', Link: '#' },
        { Social: <FaAngleDown color='white' />, Text: 'Services', Link: '#' },
        { Social: <FaAngleDown color='white' />, Text: 'Company', Link: '#' },
        { Social: <FaAngleDown color='white' />, Text: 'Pricing', Link: '#' },
        { Social: <FaAngleDown color='white' />, Text: 'Contact', Link: '#' }

    ]

    return (
        <>
            <div id='navbar' className='top-bar sticky'>
                <div className='section1'>
                    <div className='top-items'>
                        <div className='based'>Based in Melbourne Australia for all your digital needs... <a className='purple' href='#'>Learn More</a></div>
                        <div className='top-items-right'>
                            <button className='button-standard'>Customer Portal Login</button>
                            <div className='language'>EN | AUD</div>
                        </div>
                    </div>
                </div>

                <div className='section2'>
                    <div className='nintyseven'>
                        <div className='header-split'>
                            <div className='name-logo'>
                                <img className='logo' src={nextconceptsLogo} height='50' width='50' />
                                <h1 className='header-company-name'>Next Concepts</h1>
                            </div>
                            <div className='menu-share'>
                                {MenuItems.map((val, i) => (
                                    <div className='menu-icon' key={i}>
                                        <div className='menu-text'>
                                            {val.Text}
                                        </div>
                                        <div className='menu-social'>
                                            {val.Social}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="social-share">
                                {SocialShare.map((val, i) => (
                                    <div className='social-icon' key={i}><a href={`${val.Link}`}>{val.Social}{val.Text}</a></div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopBar