import React from 'react'
import { FaChrome } from "react-icons/fa";
import { GiSmartphone } from 'react-icons/gi'
import { SiEnterprisedb, SiMarketo } from 'react-icons/si'
import { BsClipboardData } from 'react-icons/bs'
import { AiOutlineShoppingCart } from 'react-icons/ai'

const ServiceCard = ({ service }) => {

    let description = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate cumque vitae, maiores dolorum tempore quod, magnam esse soluta est consequuntur, iste corporis beatae itaque ea laboriosam odio omnis commodi officiis.';

    switch (service) {
        case 'Websites':
            description = 'A website is an essential tool for businesses to establish their online presence and reach potential customers. It serves as a platform for showcasing products or services, facilitating transactions, providing customer support, and building brand awareness.';
            break;
        case 'Web Apps':
            description = 'Web apps have become increasingly popular for businesses as they provide a more interactive and engaging user experience than traditional websites. They can be used for a variety of purposes such as customer relationship management, project management, and data analysis.';
            break;
        case 'Enterprise Solutions':
            description = 'Enterprise solutions are software applications designed to address the needs of large organizations. They provide comprehensive functionality and scalability to manage complex business processes, increase productivity, and reduce operational costs..';
            break;
        case 'Data Processing':
            description = 'Data processing is a critical component of modern businesses, as it enables them to turn raw data into valuable insights that can inform decision-making. By leveraging data processing tools, businesses can improve efficiency, reduce costs, optimize operations, and gain a competitive advantage in their industry..';
            break;
        case 'Digital Marketing':
            description = 'Digital marketing allows businesses to reach a wider audience through various online channels such as social media, search engines, and email marketing. It provides the ability to track and analyze marketing efforts in real-time, enabling businesses to optimize their strategies for better ROI and customer engagement.';
            break;
        case 'Ecommerce':
            description = 'eCommerce and online shops enable businesses to sell their products and services online, providing a convenient and accessible shopping experience for customers. They also offer the ability to target specific demographics, increase customer loyalty, and expand into new markets.'
            break;
        default:
            description = '';
    }

    function renderSwitch(service) {
        switch (service) {
            case 'Website':
                return <FaChrome className='icon-header' color='#6e5ce6' size={50} />;
            case 'Web Apps':
                return <GiSmartphone className='icon-header' color='#6e5ce6' size={50} />;
            case 'Ecommerce':
                return <AiOutlineShoppingCart className='icon-header' color='#6e5ce6' size={50} />;
            case 'Digital Marketing':
                return <SiMarketo className='icon-header' color='#6e5ce6' size={50} />;
            case 'Data Processing':
                return <BsClipboardData className='icon-header' color='#6e5ce6' size={50} />;
            case 'Enterprise Solutions':
                return <SiEnterprisedb className='icon-header' color='#6e5ce6' size={50} />;
            default:
                return <FaChrome className='icon-header' color='#6e5ce6' size={50} />;
        }
    }

    return (
        <div className='service-card go-left'>
            <div>
                {renderSwitch(service)}
            </div>
            <h1 className='go-left'>{service}</h1>
            <p className='go-left'>{description}</p>
        </div>
    )
}

export default ServiceCard