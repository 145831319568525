import React from 'react'
import { useContext, useEffect } from 'react';
import SupabaseContext from '../context/SupabaseContext';
import AuthContext from '../context/AuthContext';

const AdminArea = () => {

    const { supabase } = useContext(SupabaseContext)
    const { SignIn, SignOut, isAuth, user } = useContext(AuthContext)

    useEffect(() => {
        SignIn();
    }, []);

    return (
        <>
            <div className='admin-area-container'>
                <div className='admin-area-inner'>
                    <div>
                        <h1>Next Concepts Admin Area</h1>
                    </div>
                    <div>
                        {user ?
                            <p>Hello, {user}, Welcome back!</p>
                            :
                            <p>Not Logged in yet mate!</p>
                        }
                    </div>
                </div>
            </div>
            <div className='admin-area-menu'>
                <div className='admin-area-inner'>
                    <div className='across'>
                        <div className='menu-item red'>
                            People Paying Us
                        </div>
                        <div className='menu-item'>
                            Us Paying People
                        </div>
                        <div className='menu-item'>
                            Customers
                        </div>
                        <div className='menu-item'>
                            Suppliers
                        </div>
                    </div>
                </div>
            </div>
            <div className='admin-area-container'>
                <div className='admin-area-inner'>
                    <div class='nc-badge'>Invoices</div>
                    <div class='nc-badge red toppy'>Open</div>
                </div>
                <div>
                    <table id="invoices">
                        <thead>
                            <tr>
                                <th scope="col">Invoice #</th>
                                <th scope="col">Customer</th>
                                <th scope="col">Status</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Due</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>NC-1337</td>
                                <td>Empire Staging & Design</td>
                                <td>Sent</td>
                                <td>$202.80</td>
                                <td>15/4/2023</td>
                            </tr>
                            <tr>
                                <td>NC-1338</td>
                                <td>Lakini's Juice</td>
                                <td>Sent</td>
                                <td>$24.95</td>
                                <td>17/4/2023</td>
                            </tr>
                            <tr>
                                <td>NC-1339</td>
                                <td>Lakini's Juice</td>
                                <td>Sent</td>
                                <td>$12.50</td>
                                <td>17/4/2023</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>

    )
}

export default AdminArea