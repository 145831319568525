import React from 'react'
import slider_office from '../assests/slider/slider_office.png'
import slider_code from '../assests/slider/slider_code.png'

const WhatIsYourProject = () => {
    return (
        <>
            <div className='grid-container-project'>
                <div className='item1-project'>
                    <div className='slider'>
                        <img className='slider-image' src={slider_office} />
                    </div>
                </div>
                <div className='item2-project'>
                    <div className='slider-middle'>
                        <img className='slider-image-middle' src={slider_code} />
                    </div>
                </div>
                <div className='item3-project'>
                    <div className='project-description'>
                        <h1 className='purple idea-title'>It Starts With An Idea</h1>
                        <h3>THE IDEA</h3>
                        <p>A customer's new project is to develop an online marketplace for buying and selling handmade crafts. They want a user-friendly platform that is easy to navigate, has secure payment options, and allows sellers to customize their storefronts</p>
                        <h3>THE SOLUTION</h3>
                        <p>To implement this solution using web-based technology, we can suggest building a <span className='purple'>custom e-commerce</span> website using popular platforms like <span className='purple'>WooCommerce, Shopify or Magento.</span> These platforms have a range of features including secure payment gateways, customizable storefronts, and intuitive navigation. We can work with the customer to create a design that meets their branding needs and create a user-friendly interface for their customers.</p>
                        <p>In addition to the website, we can also suggest developing a <span className='purple'>mobile application</span> to increase accessibility for customers. The mobile application can be built using responsive web design principles or as a native application for iOS and Android devices. The mobile application can have all the features of the website, including the ability to purchase items, access to a user's account, and the ability to customize storefronts.</p>
                        <div className='flexy'>
                            <button className='button-standard paddy'>Let's Do It</button>
                            <button className='button-standard-grey paddy'>Learn More</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhatIsYourProject