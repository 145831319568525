import React from 'react'
import html from '../assests/logo/html.png'
import wordpress from '../assests/logo/wordpress.png'
import mobileapp from '../assests/logo/mobile-app.png'
import techstack from '../assests/logo/techstack.png'
import website from '../assests/logo/website.png'
import cart from '../assests/logo/cart.png'


const Technology = () => {
    return (
        <>
            <div className='technology-container'>
                <div id='tech-box' className='technology-container-inner'>
                    <div className='grid-container-technology'>
                        <div className='item1-technology center'>
                            <h1 className='purple idea-title'>Then The Product Is Developed.</h1>
                            <p className='left-text'>Introducing our latest <span className='purple'>custom-built website</span> and online store, designed with the latest technology and intelligent design. We understand the importance of a <span className='purple'>seamless online experience</span>, which is why our platform is crafted to meet the highest standards of functionality, aesthetics, and user-friendliness.</p>
                            <p className='left-text'>Our website and online store are customized to cater to your <span className='purple'>specific needs</span>, and our team of experts ensures that each detail is optimized for maximum performance.</p>
                            <p className='left-text'>With a <span className='purple'>clean and modern design</span>, our website and online store offer a visually appealing and engaging platform for your customers to explore your products and services. So whether you are a small business owner or a large corporation, our custom-built website and online store will provide you with the perfect online presence to take your <span className='purple'>business to the next level</span>.</p>
                        </div>
                        <div className='item2-technology center'>
                            <h2 className='center'>Website</h2>
                            <img className='tech-image' src={website} />
                        </div>
                        <div className='item3-technology center'>
                            <h2 className='center'>Mobile App</h2>
                            <img className='tech-image' src={mobileapp} />
                        </div>
                        <div className='item4-technology center'>
                            <h2 className='center'>Online Store</h2>
                            <img className='tech-image' src={cart} />
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}

export default Technology