import React from 'react'
import Header from '../components/Header'
import Hero from '../components/Hero';
import About from '../components/About';
import Services from '../components/Services';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import WhatIsYourProject from '../components/WhatIsYourProject';
import Technology from '../components/Technology';
import Product from '../components/Product';

const Home = () => {
    return (
        <>
            <Hero />
            <WhatIsYourProject />
            <Technology />
            <Product />
            <About />
            <Services />
            {/* <Portfolio />
      <Blog /> */}
            <ContactUs />
            <Footer />
        </>
    )
}

export default Home