import './App.css';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from './pages/Home';
import AdminArea from './pages/AdminArea';
import NotFound from './pages/NotFound';
import TopBar from './components/TopBar';
import Menu from './components/Menu'

function App() {
  return (
    <>
      <TopBar />      
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin-area" element={<AdminArea />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
